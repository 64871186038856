<script>
import DigitalContentView from '@/views/DigitalContentView.vue';

export default {
    name: 'DigitalContentViewUnprotected',
    mixins: [DigitalContentView],
    data() {
        return {
            apiEmulate: true
        }
    }
}
</script>